@import "src/styles";
@font-face {
  font-family: 'Walsheim';
  font-weight: 400;
  font-display: swap;
  src:
    url('/typography/GT-Walsheim-Regular.otf') format('opentype'),
    url('/typography/GT-Walsheim-Regular.ttf') format('truetype'),
    url('/typography/GT-Walsheim-Regular.eot') format('embedded-opentype'),
    url('/typography/GT-Walsheim-Regular.woff') format('woff'),
    url('/typography/GT-Walsheim-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Walsheim';
  font-weight: 500;
  font-display: swap;
  src:
    url('/typography/GT-Walsheim-Medium.otf') format('opentype'),
    url('/typography/GT-Walsheim-Medium.ttf') format('truetype'),
    url('/typography/GT-Walsheim-Medium.eot') format('embedded-opentype'),
    url('/typography/GT-Walsheim-Medium.woff') format('woff'),
    url('/typography/GT-Walsheim-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Walsheim';
  font-weight: 700;
  font-display: swap;
  src:
    url('/typography/GT-Walsheim-Bold.otf') format('opentype'),
    url('/typography/GT-Walsheim-Bold.ttf') format('truetype'),
    url('/typography/GT-Walsheim-Bold.eot') format('embedded-opentype'),
    url('/typography/GT-Walsheim-Bold.woff') format('woff'),
    url('/typography/GT-Walsheim-Bold.woff2') format('woff2');
}
