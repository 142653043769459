@mixin levelTypography(
  $kind,
  $color: $color-black,
  $weight: $font-weight-bold,
  $family: $font-family
) {
  @include fluidSizing('font-size', map-get($font-sizes-level, $kind));
  @include fluidSizing('line-height', map-get($font-lines-level, $kind));
  & {
    font-family: $family;
    font-weight: $weight;
    color: $color;
  }
}

@mixin bodyTypography(
  $kind,
  $color: $color-black,
  $weight: $font-weight-normal,
  $family: $font-family
) {
  font-size: map-get($font-sizes-body, $kind);
  line-height: map-get($font-lines-body, $kind);
  font-family: $family;
  font-weight: $weight;
  color: $color;
}
